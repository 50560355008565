import moment from 'moment';

export default {

	methods: {

		getNeedPayAmount(price, count, payAmount = 0) {

			let amount = this.getTotalAmount(price, count);
			return this.getRoundNumber(amount - payAmount);
		},

		getTotalAmount(price, count, defaultAmount = 0, maxCount = 0) {

			maxCount = parseInt(maxCount);
			if (maxCount > 0 && count > maxCount)
				count = maxCount;

			let amount = price * count;
			if (amount == 0)
				amount = defaultAmount;

			return this.getRoundNumber(Math.max(amount, 1));
		},

		getOrderDuration(dateStart, dateFinish, maxCount = 0) {

			if (!dateFinish)
				dateFinish = this.getCurrentFullDateTime();

			let timestamp = moment(dateFinish).diff(moment(dateStart), 'milliseconds');
			let minutes = moment(dateFinish).diff(moment(dateStart), 'minutes');

			if (minutes > maxCount)
				timestamp = maxCount * 60 * 1000;

			return moment.utc(timestamp).format('H[h] mm[m] ss[s]');
		},

		getCalcOrderCount(dateStart, dateFinish, count = 0, maxCount = 0) {

			if (!dateFinish)
				dateFinish = this.getCurrentFullDateTime();

			let duration = moment(dateFinish).diff(moment(dateStart), 'minutes');
			if (count > 0)
				duration = count;

			if (maxCount > 0 && duration > maxCount)
				duration = maxCount;

			return duration;
		}

	}
}