import haversine from 'haversine';

export default {

	data: () => ({
		geoLocation: {
			lat: 32.816057,
			lng: 35.003125
		}
	}),

	methods: {

		async initGeoLocation(isShowModal = true) {

			await navigator?.geolocation?.getCurrentPosition(
				(position) => {

					if (position?.coords?.latitude && position?.coords?.longitude) {

						this.geoLocation.lat = position.coords.latitude;
						this.geoLocation.lng = position.coords.longitude;
					}
				}, () => {

					if (isShowModal)
						this.showModal('Please enable geolocation access in your browser settings and refresh page');
				},
			);
		},

		getPlaceDistance(placeData) {

			if (!placeData.lat || !placeData.lng)
				return null;

			return haversine({
				latitude: placeData.lat,
				longitude: placeData.lng
			}, {
				latitude: this.geoLocation.lat,
				longitude: this.geoLocation.lng
			});
		},

		getFormatDistance(distance) {

			if (!distance)
				return '';

			return parseFloat(distance.toFixed(2)) + ' km';
		},

		getPlaceImageUrl(placeImageData) {

			if (!placeImageData)
				return null;

			return 'files/placeimage/' + placeImageData.file + '.' + placeImageData.fileExt;
		},

		getPlaceThumbImageUrl(placeImageData) {

			if (!placeImageData)
				return null;

			return 'files/placeimage/thumbs/' + placeImageData.file + '.' + placeImageData.fileExt;
		}

	}
}