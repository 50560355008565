<template>
  <div class="place-page">

    <div class="slider-wrapper" v-if="!isHideTutor">
    <div class="slider-block">
      <swiper
          :modules="modules"
          :slides-per-view="1"
          :space-between="50"
          :pagination="true"
      >
        <swiper-slide>
          <div class="inner-slide inner-slide-1">
            <div class="title">1. Find gyms</div>
            <div class="description">
              <strong>Find gyms</strong>
              on the map that suit your budget and lifestyle. Come to train!
            </div>
            <div class="image">
              <img src="~@/assets/img/intro-slide-1-img.jpg" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="inner-slide inner-slide-2">
            <div class="title">2. Scan the QR</div>
            <div class="description">
              <strong>Scan the QR</strong>
              code on the reception desk by using the scan button on the bottom menu. <br />
              Your workout starting!
            </div>
            <div class="image">
              <img src="~@/assets/img/intro-slide-2-img.jpg" />
            </div>
            <div class="description description-bottom">
              At the end of your workout <strong>scan the QR code again</strong>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="inner-slide inner-slide-3">
            <div class="title">3. Pay</div>
            <div class="description">
              <strong>Pay for the minutes</strong> you spent in the gym
            </div>
            <div class="image">
              <img src="~@/assets/img/intro-slide-3-img.png" />
            </div>
            <div class="buttons">
              <a href="#" class="btn btn-form" @click.prevent="closeTutor()">START</a>
            </div>
            <div class="chbs">
              <div class="form-check form-check-inline" v-if="isShowTutorCheckbox">
                <input class="form-check-input" type="checkbox" value="" id="tutor-save-confirm" v-model="isHideTutorSave" >
                <label class="form-check-label" for="tutor-save-confirm">
                  don't show again
                </label>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    </div>

    <div class="help-wrapper" v-if="isShowHelp">
      <div class="help-block">
        <div class="close-div">
          <a href="#" @click.prevent="showHelp()"><span class="fa fa-close"></span></a>
        </div>
        <div class="title">
          Let’s begin!
        </div>
        <p>
          1. <strong>Find gyms</strong> on the map that suit your budget and lifestyle - come to train!
        </p>
        <p>
          2. <strong>Scan the QR</strong> code on the reception desk by using the scan button on the bottom menu and
          your workout starting!
        </p>
        <p>
          3. At the end of your workout <strong>scan the QR code again</strong>
        </p>
        <p>
          4. <strong>Pay for the minutes</strong> you spent in the gym
        </p>
      </div>
    </div>

    <div id="map"></div>

    <div class="top-block">
      <router-link :to="{path: '/place'}" class="button-filter">
        <span class="fa fa-search"></span>
      </router-link>
      <a href="#" class="button-filter" @click.prevent="showFilterModal()">
        <span class="fa fa-tasks"></span>
      </a>
    </div>

    <div class="top-block-left">
      <a href="#" class="button-filter" @click.prevent="showTutor()">
        <span class="fa fa-question"></span>
      </a>
    </div>

    <div class="bottom-block">
      <div class="row">
        <div class="col-4 align-self-center text-right">
          <router-link :to="{path: '/user/edit'}" class="button-option-outer">
            <span class="button-option">
              <span class="fa fa-user"></span>
            </span>
          </router-link>
        </div>
        <div class="col-4 text-center">
          <router-link :to="{path: '/place/code/view'}" class="button-option-outer big">
            <span class="button-option button-option-main">
              <span class="fa fa-qrcode"></span>
              <div class="text">{{ orderData ? 'Finish' : 'Start' }}</div>
            </span>
          </router-link>
        </div>
        <div class="col-4 align-self-center">
          <router-link :to="{path: '/order'}" class="button-option-outer ord" v-if="orderData">
            <span class="button-option">
              {{ duration }}
            </span>
          </router-link>
          <router-link :to="{path: '/order'}" class="button-option-outer" v-if="!orderData">
            <span class="button-option button-option-main">
              <span class="fa fa-ellipsis-h f-top"></span>
            </span>
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="isFilterModalShow">
      <div class="modal show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-fullscreen">
          <div class="modal-content">
            <div class="modal-body">
              <div class="d-flex flex-column justify-content-between h-100">
                <div>
                  <p>
                    <a href="#" class="button-back" @click.prevent="closeFilterModal()">
                      <span class="fa fa-arrow-left"></span>
                    </a>
                  </p>
                  <div class="text-center">
                    <span v-for="(priceFilterData) in priceFilterList" :key="priceFilterData.type">
                      <a href="#" class="btn btn-filter mb-2" :class="{'btn-filter-active': this.tempFilter.price == priceFilterData.type}" @click.prevent="setPriceTempFilter(priceFilterData)">
                        {{ priceFilterData.title }}
                      </a>
                    </span>
                  </div>
                  <div class="filter-divider"></div>
                  <div class="text-center">
                    <span v-for="(placeTagData) in placeTagList" :key="placeTagData.id">
                      <a href="#" class="btn btn-sm mb-2 btn-filter" :class="{'btn-filter-active': this.tempFilter.tagIds.indexOf(placeTagData.id) >= 0}" @click.prevent="setTagTempFilter(placeTagData)">
                        {{ placeTagData.title }}
                      </a>
                    </span>
                  </div>
                </div>
                <div>
                  <p class="text-center">
                    <a href="#" class="btn btn-filter-save" @click.prevent="saveFilterModal()">Submit</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop show"></div>
    </div>

  </div>
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader'
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Pagination} from 'swiper';
import "swiper/swiper-bundle.css";
import PlaceMixin from '@/modules/place/mixins/PlaceMixin';
import OrderMixin from '@/modules/order/mixins/OrderMixin';
import {OrderStatusEnum} from '@/modules/order/enums/OrderEnum';

export default {

  mixins: [PlaceMixin, OrderMixin],

  components: {Swiper, SwiperSlide},

  setup() {
    return {
      modules: [Pagination],
    };
  },

  data: () => ({
    placeList: [],
    placeTagList: [],
    orderData: null,

    isFilterModalShow: false,

    filter: {
      price: null,
      tagIds: []
    },
    tempFilter: {
      price: null,
      tagIds: []
    },
    priceFilterList: [],

    mapObject: null,
    timerObject: null,
    duration: null,

    isHideTutor: true,
    isShowTutorCheckbox: true,
    isHideTutorSave: false,
    isShowHelp: false
  }),

  async mounted() {

    if (!await this.checkUser())
      return;

    await this.initGeoLocation();

    this.initPriceFilter();

    await this.doPlaceMap();

    await this.initMap();

    this.initTutor();

    this.initOrderTimer();

    const priceSet = sessionStorage.getItem('mapPriceSettings');
    const tagIdsSet = sessionStorage.getItem('mapTagIdsSettings');

    if (priceSet || tagIdsSet) {

      if (priceSet) {
        this.tempFilter.price = priceSet;
      }

      if (tagIdsSet) {
        tagIdsSet.split(',').forEach(element => this.tempFilter.tagIds.push(Number(element)));
      }

      this.saveFilterModal();
    }
  },

  methods: {

    async doPlaceMap() {

      let apiHolder = await this.sendApiGet(['api/place/index']);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.placeList)
        this.placeList = apiHolder.data.placeList;
      if (apiHolder.data?.placeTagList)
        this.placeTagList = apiHolder.data.placeTagList;
      if (apiHolder.data?.orderData)
        this.orderData = apiHolder.data.orderData;
    },

    initOrderTimer() {

      if (!this.orderData || !this.orderData.dateStart)
        return;

      this.duration = this.getOrderDuration(this.orderData.dateStart, this.orderData.dateFinish, this.orderData.maxCount);

      if (this.orderData.status != OrderStatusEnum.open)
        return;

      this.timerObject = setInterval(() => {

        this.duration = this.getOrderDuration(this.orderData.dateStart, this.orderData.dateFinish, this.orderData.maxCount);
      }, 1000);
    },

    async initMap() {

      const loader = new Loader({
        apiKey: process.env.VUE_APP_MAP_KEY,
        libraries: ['places']
      });

      await loader.load();

      this.mapObject = new window.google.maps.Map(document.getElementById('map'), {
        center: this.geoLocation,
        zoom: this.$store.state.mapZoom,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        gestureHandling: 'greedy',
        styles: [
          { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
          { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
          },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2f3948" }],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263c" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
          },
        ],
      });

      new window.google.maps.Marker({
        position: {
          lat: this.geoLocation.lat,
          lng: this.geoLocation.lng
        },
        map: this.mapObject,
        icon: {
          scaledSize: new window.google.maps.Size(24, 24),
          url: require('../../../assets/img/map-user-icon-3.png')
        }
      });

      this.placeList.forEach((placeData, index) => {

        if (!placeData.lat || !placeData.lng)
          return;

        this.placeList[index].markerData = new window.google.maps.Marker({
          position: {
            lat: placeData.lat,
            lng: placeData.lng
          },
          label: {
            text: this.getPrice(placeData.price) + this.getCurrencySymbol(),
            className: 'map-label',
          },
          icon: {
            scaledSize: new window.google.maps.Size(44, 64),
            url: require('../../../assets/img/map-place-icon.png')
          },
          map: this.mapObject,
          title: placeData.title
        });

        window.google.maps.event.addListener(this.placeList[index].markerData, 'click', async () => {

          await this.redirect('/place/view/' + placeData.id);
        });

      });
    },

    initPriceFilter() {

      this.priceFilterList.push({
        type: '-0.5',
        title: '... - 0.5' + this.getCurrencySymbol()
      }, {
        type: '0.5-1',
        title: '0.5' + this.getCurrencySymbol() + ' - 1' + this.getCurrencySymbol()
      }, {
        type: '1-',
        title: '1' + this.getCurrencySymbol() + ' - ...'
      });
    },

    showFilterModal() {

      this.setFilterModalShow(true);
    },

    closeFilterModal() {

      this.tempFilter = this.getCopyValue(this.filter);
      this.setFilterModalShow(false);
    },

    saveFilterModal() {

      this.filter = this.getCopyValue(this.tempFilter);
      sessionStorage.setItem('mapPriceSettings', this.tempFilter.price);
      sessionStorage.setItem('mapTagIdsSettings', this.tempFilter.tagIds.join());
      this.updateMapMarker();
      this.setFilterModalShow(false);
    },

    setFilterModalShow(isFilterModalShow) {

      this.isFilterModalShow = isFilterModalShow;
    },

    setTagTempFilter(placeTagData) {

      const index = this.tempFilter.tagIds.indexOf(placeTagData.id);

      if (index >= 0)
        this.tempFilter.tagIds.splice(index, 1);
      else
        this.tempFilter.tagIds.push(placeTagData.id);
    },

    setPriceTempFilter(priceFilterData) {

      if (this.tempFilter.price == priceFilterData.type)
        this.tempFilter.price = null;
      else
        this.tempFilter.price = priceFilterData.type;
    },

    updateMapMarker() {

      this.placeList.forEach((placeData) => {

        let isShow = true;

        if (this.filter.price == '-0.5' && placeData.price > 0.5)
          isShow = false;
        if (this.filter.price == '0.5-1' && (placeData.price < 0.5 || placeData.price > 1))
          isShow = false;
        if (this.filter.price == '1-' && placeData.price < 1)
          isShow = false;

        if (this.filter.tagIds.length > 0 && placeData.placeTagLinkList) {

          let isTagShow = false;
          placeData.placeTagLinkList.forEach((placeTagLinkData) => {

            if (this.filter.tagIds.indexOf(placeTagLinkData.tagId) >= 0)
              isTagShow = true;
          });

          if (!isTagShow)
            isShow = false;
        }

        placeData.markerData?.setVisible(isShow);
      });
    },

    initTutor() {

      if (localStorage.getItem('isHideTutor'))
        this.isHideTutor = true;
      else
        this.isHideTutor = false;
    },

    closeTutor() {

      this.isHideTutor = true;

      if (this.isHideTutorSave)
        localStorage.setItem('isHideTutor', true);
    },

    showTutor() {

      this.isShowTutorCheckbox = false;
      this.isHideTutor = false;
    },

    showHelp() {

      this.isShowHelp = !this.isShowHelp;
    },

  },

  beforeUnmount() {

    if (this.timerObject)
      clearInterval(this.timerObject);
  }

}
</script>

<style scoped>

#map {
  height: 100vh;
}

.btn-filter {
  background: #A8A8A8;
  border-color: #A8A8A8;
  color: #FFF;
  padding: 9px 29px;
  border-radius: 38px;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-filter-active {
  background: #343434;
  border-color: #343434;
  color: #2CDAE5;
}

.btn-filter-save {
  background: #343434;
  border-radius: 38px;
  text-transform: uppercase;
  color: #00F0FF;
  letter-spacing: 0.05em;
  font-weight: 700;
  font-size: 14px;
  padding: 25px 35px;
  max-width: 500px;
  width: 100%;
}

.filter-divider {
  max-width: 75px;
  margin: 20px auto 25px;
  border: 1px solid #dedede;
}

.top-block-left {
  top: 35px;
  left: 15px;
  position: absolute;
}

.top-block {
  top: 35px;
  right: 15px;
  position: absolute;
}

.button-filter {
  background: #8c8c8c;
  color: #FFF;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  margin: 0 7px;
}

.bottom-block {
  bottom: 35px;
  left: calc(50% - 175px);
  position: absolute;
  min-width: 350px;
}

.button-option-outer {
  background: #d2d2d2;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}

.button-option-outer .button-option {
  background: #F1F1F1;
  color: #3F414E;
  width: 64px;
  height: 64px;
  font-size: 28px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}

.button-option-outer.big {
  width: 100px;
  height: 100px;
}

.button-option-outer.big .button-option {
  width: 84px;
  height: 84px;
  font-size: 44px;
}

.button-option-outer.big .button-option .fa {
  position: relative;
  top: 3px;
}

.button-option-outer.ord {
  background: #2CDAE5;
}

.button-option-outer.ord .button-option {
  font-size: 12px;
  text-align: center;
}

.f-top {
  position: relative;
  top: 3px;
}

.button-option-main {
  flex-direction: column;
  line-height: 30px;
}

.button-option-main .text {
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  text-transform: uppercase;
}

</style>